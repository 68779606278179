import $ from "jquery";
import ScrollMagic from 'scrollmagic';
import {TimelineMax, Power3, TweenMax, gsap} from 'gsap';
import './scrollmagicStart.js';

import slick from 'slick-carousel';
import headerAnimation from './headerAnim.js';
import './sliderDisp.js';







const mainPage = $('.main-page');
let animationIsFinished = false;
let slideStatusAnimate = false;
let timeoutId;
let pathPrefix = '';
let sliderSpeed  = 600;

function imgPath(path) {return pathPrefix + 'assets/img/'+ path}





function resize() {
    if($(window).width() >= 1024){
        $('#sliderExpCanvas canvas').width($(window).width() / 2);
    } else {
        $('#sliderExpCanvas canvas').width($(window).width());

    }
    $('#sliderExpCanvas canvas').height($(window).height());
    $('#bg-slider canvas').width($(window).width());
    $('#bg-slider canvas').height($(window).height());
}

if(mainPage.length > 0){
    $('.intro').addClass('active');
    setTimeout(() => {
        $('.intro').addClass('pulsingHeart');
    }, 3000);

    // fields for first slider( second section on main page)
    let slSrc1 = $('#sliderExpSrc .inner-slide'),
        slTitles1 = [],
        slTxts1 = [],
        slNum1 = [],
        slLinks1 = [];

    slSrc1.each(function(i, value){
        let _that = $(this);
        let slTitle = $('.title', _that).text();
        let slTxt = $('.text', _that).text();
        let slNum = i + 1;
        let slLink = $('.link', _that).text();

        slTitles1.push(slTitle);
        slTxts1.push(slTxt);
        slNum1.push(slNum);
        slLinks1.push(slLink);
    });


    // fields for secon slider(4 section on main page) - background-image slider
    let slSrc2 = $('#bg-slider-src .inner-slide'),
        slYear2 = [],
        slName2 = [],
        slTitles2 = [],
        slTxts2 = [],
        slLinks2 = [];

    slSrc2.each(function(i, value){
        let _that = $(this);
        let slYear = $('.year', _that).text();
        let slName = $('.name', _that).text();
        let slTitle = $('.title', _that).text();
        let slTxt = $('.text', _that).text();
        let slLink = $('.link', _that).text();

        slYear2.push(slYear);
        slName2.push(slName);
        slTitles2.push(slTitle);
        slTxts2.push(slTxt);
        slLinks2.push(slLink);
    });


    // animation for section on main page
    let firstSection = {
        elem: {
            title: $('.intro__title-text'),
            uptitle: $('.intro__title-uptext'),
            btn: $('.intro__link'),
            columns: $('.intro__portfolio-columns__inner')
        },
        enter: function(){
            let tlIntro = new TimelineMax();
            tlIntro.fromTo(firstSection.elem.title, 2,
                {y: '50px',opacity: 0},
                {y: 0, opacity: 1}
            );
            if(firstSection.elem.uptitle.length > 0){
                tlIntro.fromTo(firstSection.elem.uptitle, 2,
                    {y: '20px',opacity: 0},
                    {y: 0, opacity: 1},
                    0.5
                );
            }
            if(firstSection.elem.btn.length > 0){
                tlIntro.fromTo(firstSection.elem.btn, 2,
                    {y: '20px',opacity: 0},
                    {y: 0, opacity: 1},
                    0.5
                );
            }
            if(firstSection.elem.columns.length > 0){
                let arrCol = [];

                $.each(firstSection.elem.columns, function(i,el){
                    arrCol.push($(el));
                })
                console.log(arrCol);
                tlIntro.staggerFromTo(arrCol, 1,
                    {y: '20px',opacity: 0},
                    {y: 0, opacity: 1},
                    0.66, 0.1
                );
            }
        },
        leave: function(){
            let tlIntro = new TimelineMax();
            tlIntro.fromTo(firstSection.elem.title, 2,
                {y: 0, opacity: 1},
                {y: '50px',opacity: 0}
            );

        }
    }

    let secondSection = {
        elem: {
            title: $('#sliderExpTitle'),
            text: $('#sliderExpText'),
            num1: $('#sliderExpNum1'),
            num2: $('#sliderExpNum2').closest('.experience-slide__img-num'),
            footer: $('#sliderExpFooter'),
            link: $('#sliderExpLink')
        },
        startSet: function(){
            // console.log(secondSection.elem.text);
            let tlSlide1_start = new TimelineMax();
            tlSlide1_start
                .set(secondSection.elem.title, {
                    y: '100%',
                    opacity: 0
                })
                .set(secondSection.elem.text, {
                    y: '100%',
                    opacity: 0
                })
                .set(secondSection.elem.num1, {
                    x: '-20%',
                    opacity: 0
                })
                .set(secondSection.elem.num2, {
                    y: '-100%',
                    opacity: 0
                })
                .set(secondSection.elem.footer,{
                    opacity: 0
                })
        },
        leave: function(){
            let tlSlide1_leave = new TimelineMax();
            tlSlide1_leave
                .to(secondSection.elem.title, sliderSpeed / 1000, {
                    y: '100%',
                    opacity: 0,
                    ease: Power3.easeOut
                })
                .to(secondSection.elem.text, sliderSpeed / 1000, {
                    y: '100%',
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)
                .to(secondSection.elem.num1, sliderSpeed / 1000, {
                    x: '-20%',
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)
                .to(secondSection.elem.num2, sliderSpeed / 1000, {
                    y: '-100%',
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)
                .to(secondSection.elem.footer, sliderSpeed / 1000, {
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)

        },
        enter: function(){
            let tlSlide_enter = new TimelineMax();
            tlSlide_enter
                .fromTo(secondSection.elem.title, sliderSpeed / 1000,
                    {
                        y: '100%',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        y: '0',
                        opacity: 1,
                        ease: Power3.easeOut
                    }
                )
                .fromTo(secondSection.elem.text, sliderSpeed / 1000,
                    {
                        y: '100%',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        y: '0',
                        opacity: 1,
                        ease: Power3.easeOut
                    },
                 0)
                .fromTo(secondSection.elem.num1, sliderSpeed / 1000,
                    {
                        x: '-20%',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        x: '0',
                        opacity: 1,
                        ease: Power3.easeOut
                    }
                , 0)
                .fromTo(secondSection.elem.num2, sliderSpeed / 1000,
                    {
                        y: '-100%',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        y: '0',
                        opacity: 1,
                        ease: Power3.easeOut
                }, 0)
                .fromTo(secondSection.elem.footer, sliderSpeed / 1000,
                    {
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        opacity: 1,
                        ease: Power3.easeOut
                }, 0)

        },
        update: function(i){

            secondSection.elem.title.text(slTitles1[i]);
            secondSection.elem.text.text(slTxts1[i]);
            if(i > 9){
                secondSection.elem.num1.text('0' + slNum1[i]);
                $('#sliderExpNum2', secondSection.elem.num2).text(slNum1[i]);
            } else {
                secondSection.elem.num1.text('00' + slNum1[i]);
                $('#sliderExpNum2', secondSection.elem.num2).text('0' + slNum1[i]);
            }
            secondSection.elem.link.attr('href', slLinks1[i]);

        }
    }

    let thirdSection = {
        elem: {
            title: $('.about-section .right__title', mainPage),
            text: $('.about-section .right p', mainPage)
        },
        startSet: function(){
            let tlThirdSectionStart = new TimelineMax();
            tlThirdSectionStart
                .set(thirdSection.elem.title,{y: '-10px', opacity: 0})
                .set(thirdSection.elem.text,{y: '-10px', opacity: 0})

        },
        enter: function(){
            let tlThirdSectionEnter = new TimelineMax();
            tlThirdSectionEnter.fromTo(thirdSection.elem.title, 2,
                {y: '-10px',opacity: 0},
                {y: 0, opacity: 1}
            );
            tlThirdSectionEnter.staggerFromTo(thirdSection.elem.text, 0.8, {y: '-10px', opacity: 0}, {y: 0, opacity: 1}, 0.05, 0);
        },
        leave: function(){
            let tlThirdSectionLeave = new TimelineMax();
            tlThirdSectionLeave.fromTo(thirdSection.elem.title, 2,
                {y: 0, opacity: 1},
                {y: '-10px', opacity: 0}
            )
            tlThirdSectionLeave.staggerFromTo(thirdSection.elem.text, 0.8, {y: 0, opacity: 1}, {y: '-10px', opacity: 0}, 0.05, 0);
        }
    }

    let fourSection = {
        elem: {
            year: $('#sliderProjYear span, #sliderProjYearMob'),
            name: $('#sliderProjName'),
            title: $('#sliderProjTitle'),
            text: $('#sliderProjText'),
            link: $('#sliderProjLink')
        },
        startSet: function(){
            let tlSlide1_start = new TimelineMax();
            tlSlide1_start
            .set(fourSection.elem.year, {
                // y: '100%',
                rotateX: '-109deg',
                opacity: 0
            })
            .set(fourSection.elem.title, {
                // y: '100%',
                rotateX: '-109deg',
                translateZ: '-40px',
                opacity: 0
            })
            .set(fourSection.elem.text, {
                // y: '100%',
                translateZ: '-30px',
                opacity: 0
            })
        },
        leave: function(){
            // console.log(slTitles2);
            // console.log(slTxts2);
            // console.log(slNum2);
            // console.log(slLinks2);
            let tlSlide1_leave = new TimelineMax();
            tlSlide1_leave
                .to(fourSection.elem.year, sliderSpeed / 1000, {
                    // y: '100%',
                    rotateX: '-109deg',
                    opacity: 0,
                    ease: Power3.easeOut
                })
                .to(fourSection.elem.title, sliderSpeed / 1000, {
                    // y: '100%',
                    rotateX: '-109deg',
                    translateZ: '-40px',
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)
                .to(fourSection.elem.text, sliderSpeed / 1000, {
                    // y: '100%',
                    translateZ: '-30px',
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)

        },
        enter: function(){
            let tlSlide_enter = new TimelineMax();
            tlSlide_enter
                .fromTo(fourSection.elem.year, sliderSpeed / 1000,
                    {
                        // y: '100%',
                        rotateX: '-109deg',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        // y: '0',
                        rotateX: '0deg',
                        opacity: 1,
                        ease: Power3.easeOut
                    }
                )
                .fromTo(fourSection.elem.title, sliderSpeed / 1000,
                    {
                        rotateX: '-109deg',
                        translateZ: '-40px',
                        // y: '100%',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        rotateX: '0deg',
                        translateZ: '0px',
                        // y: '0',
                        opacity: 1,
                        ease: Power3.easeOut
                    }
                , 0)
                .fromTo(fourSection.elem.text, sliderSpeed / 1000,
                    {
                        // y: '100%',
                        translateZ: '-30px',
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    {
                        translateZ: '0px',
                        // y: '0',
                        opacity: 1,
                        ease: Power3.easeOut
                    },
                0)


        },
        update: function(i){
            fourSection.elem.year.text(slYear2[i]);
            fourSection.elem.name.text(slName2[i]);
            fourSection.elem.title.text(slTitles2[i]);
            fourSection.elem.text.text(slTxts2[i]);
            fourSection.elem.link.attr('href', slLinks2[i]);
        }
    }

    function test(e){
        console.log(e);
    }


    // animation on scroll

    let section1 = new ScrollMagic.Scene({triggerElement: ".layout__main .section.intro"})
    // .setClassToggle(this, "is-active")
    .setTween(firstSection.enter)
    .reverse(false)
    .addTo(window.controller);

    let section2 = new ScrollMagic.Scene({triggerElement: ".layout__main .section.experience-slide"})
    // .setClassToggle(this, "is-active")
    .setTween(secondSection.enter)
    .reverse(false)
    .addTo(window.controller);

    let section3 = new ScrollMagic.Scene({triggerElement: ".layout__main .section.about-section"})
    // .setClassToggle(this, "is-active")
    .setTween(thirdSection.enter)
    .reverse(false)
    .addTo(window.controller);

    let section4 = new ScrollMagic.Scene({triggerElement: ".layout__main .section.project-section"})
    // .setClassToggle(this, "is-active")
    .setTween(fourSection.enter)
    .reverse(false)
    .addTo(window.controller);





    // document.ready
    $(function(){
        resize();
        firstSection.enter();





        if($(window).width() > 1023){
            secondSection.startSet();
            thirdSection.startSet();
            fourSection.startSet();
        }

        //src for first disp slider
        let $parent = $('#sliderExpCanvas');
        let canvasWidth = $(window).width() > 1023 ? $(window).width() / 2 : $(window).width();
        let canvasHeight = $(window).height();
        let src = $('#sliderExpSrc .inner-slide');
        let countSlide = $('#sliderExpCount');
        var spriteImages = $('#sliderExpSrc .inner-slide img');
        var spriteImagesSrc = [];
        var spriteImagesSizes = {};

        for ( var i = 0; i < spriteImages.length; i++ ) {
            var img = spriteImages[i];

            var srcImg = img.getAttribute('src');
            spriteImagesSrc.push( srcImg );

            spriteImagesSizes[srcImg] = { width: img.width, height: img.height};
        }
        countSlide.text('0' + spriteImages.length);

        // init first slider
        var initCanvasSlideshow = new CanvasSlideshow({
            elemToAppend: document.getElementById('sliderExpCanvas'),
            stageWidth: canvasWidth,
            stageHeight: canvasHeight,
            fullScreen: true,
            sprites: spriteImagesSrc,
            spriteSizes: spriteImagesSizes,
            // displacementImage: imgPath('sliderExp/rdisp.png'),
            displacementImage: imgPath('sliderExp/none.png'),
            autoPlay: true,
            navElement: document.querySelectorAll('.experience-slide__btn'),
            autoPlaySpeed: [0.3, 0.3],
            displaceScale: [800, 500],
            displaceAutoFit: true,
            displacementCenter: true,
            interactive: true,
            interactionEvent: 'both', // 'click', 'hover', 'both',
            ieHoverScale: [15, 15],
            ieClickScale: [500, 100]
        });


        //Disp slider 2 - src
        let $bgSlider = $('#bg-slider'),
            $bgSliderSrc = $('#bg-slider-src'),
            bgSliderWidth = $(window).width(),
            bgSliderHeight = $(window).height(),
            spriteImagesBg = $('img', $bgSliderSrc),
            spriteImagesSrcBg = [],
            spriteImagesSrcSizes = {};
        for ( var i = 0; i < spriteImagesBg.length; i++ ) {
            var img = spriteImagesBg[i];

            var srcImg = img.getAttribute('src');

            spriteImagesSrcBg.push( srcImg );
            spriteImagesSrcSizes[srcImg] = { width: img.width, height: img.height};
        }
        // init bg slider
        var initCanvasSlideshow2 = new CanvasSlideshow({
            elemToAppend: document.getElementById('bg-slider'),
            stageWidth: bgSliderWidth,
            stageHeight: bgSliderHeight,
            fullScreen: true,
            sprites: spriteImagesSrcBg,
            spriteSizes: spriteImagesSrcSizes,
            displacementImage: imgPath('sliderProject/crystalize_big.jpg'),
            autoPlay: false,
            navElement: document.querySelectorAll('.project__slider__btn'),
            autoPlaySpeed: [0.2, 0.2],
            displaceScale: [800, 400],
            displaceAutoFit: true,
            displacementCenter: true,
            interactive: true,
            interactionEvent: 'both', // 'click', 'hover', 'both',
            ieHoverScale: [20, 20],
            ieClickScale: [500, 100]
        });


    })






    // project bg & slick sliders

    let $sSlider = $('#p-slider'),
        $sArrowPrev = $('#p-slider-prev'),
        $sArrowNext = $('#p-slider-next'),
        $sDots = $('#p-slider-dots');


    $sSlider.slick({
        // infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: $sArrowPrev,
        nextArrow: $sArrowNext,
        dots: true,
        appendDots: $sDots,
        autoplay: true,
        focusOnSelect: true,
        autoplaySpeed: 3000,
        // draggable: false,
        // swipe: false,
        // touchMove: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: false,
                    // draggable: true,
                    // swipe: true,
                    // touchMove: true,
                }
            },

        ]
    });

    let positionArrowsChange = false;

    function arrowPosition(){
        let elem = $('.project__footer');
        let mobTarget = $('.p-info__item');
        let deskTarget = $('.project');

        if(elem.length < 1 && mobTarget.length < 1 && deskTarget.length < 1) return true;


        if(positionArrowsChange === false){
            if(window.matchMedia("(max-width: 767px)").matches){
                elem.appendTo(mobTarget);
                positionArrowsChange = true;
            }
        } else {
            if(window.matchMedia("(min-width: 768px)").matches){
                elem.appendTo(deskTarget);
                positionArrowsChange = false;

            }
        }

    }
    $(window).on("orientationchange resize load", arrowPosition);



    $sSlider.on("beforeChange", function(event, slick, currentSlide, nextSlide){

        document.dispatchEvent(new CustomEvent("changeSlickSlide", {
            detail: {
              current: nextSlide,
            }
        }));



        $sSlider.css('pointer-events','none');
        setTimeout(() => {
            $sSlider.css('pointer-events','all');
        }, 300);
    })


    // $sSlider.on("afterChange", function(event, slick, currentSlide, nextSlide){


    // });

    $(document).on('click', '.project__slider__btn', function(){
        $('.project__slider__btn').addClass('disabled');
        setTimeout(() => {
            $('.project__slider__btn').removeClass('disabled');
        }, 300);
    })


    $(document).on('currentSlide', function(e){
        // console.log('asdasdasdaffd');
        let slider = $(e.detail.sliderParent);
        let index = e.detail.currentIndex;
        if(slider.hasClass('experience-slide-canvas')){
            secondSection.leave();
            secondSection.update(index);
            secondSection.enter();
        }
        if(slider.hasClass('project__bg-slider')){
            fourSection.leave();
            fourSection.update(index);
            fourSection.enter();

        }
    });
    var aForResize;
    $(window).resize(function() {
        clearTimeout(aForResize);
        aForResize = setTimeout(function() {
            resize();
        },200);
    });







}

