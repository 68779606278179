window.$ = window.jQuery = require('jquery');
// import {TimelineMax, Power3} from 'gsap';
import ScrollMagic from 'scrollmagic';
import {TimelineMax, Power3, TweenMax, gsap} from 'gsap';
import './scrollmagicStart.js';
// import './salvattore.min.js';
var Masonry = require('masonry-layout');
var imagesLoaded = require('imagesloaded');


const portfolioPage = $('.portfolio-page');
const portfolioInnerPage = $('.portfolio-inner-page');


let $animWrap = $('.img-animation__wrapper');

if(portfolioInnerPage.length > 0 && $animWrap.length > 0){
    let $animImgOverlay = $('.img-animation__overlay');
    let $animImgWrap = $('.img-animation');
    let $animImg = $('.img-animation img');
    let $animImgBlock = $('.img-animation__wrapper');


    // let tl = new TimelineMax();


    $(function() {
        // min height & width
        $animImg.each(function (i,el) {
            let minW = $(el).width();
            let minH = $(el).height();
            $('.img-animation__wrapper').eq(i).find('.img-animation__overlay').css('height', minH);
            $(el).closest('.img-animation').css({
                'min-height': minH,
                'width': 0
            });
            $(el).css({
                'height': minH,
                'width': minW
            })
        });


        $animImgBlock.each(function (i,el) {
            new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.9
            })
            .on('enter', function(){
                let overlay = $('.img-animation__overlay', $(el));
                let img = $('.img-animation', $(el));
                overlay.animate({
                    width: '100%',
                    opacity: 1
                }, 700)
                setTimeout(() => {
                    img.animate({
                        width: '100%',
                        opacity: 1
                    }, 600)
                }, 200);

            })
            // .setClassToggle(this, "is-active")

            .reverse(false)
            .addTo(controller);
        });
    });



}




$(function() {
    if(portfolioInnerPage.length > 0){
        $('.intro').addClass('active');
        setTimeout(() => {
            $('.intro').addClass('pulsingHeart');
        }, 3000);
    }
    if(portfolioPage.length > 0){
        $('.intro').addClass('active');
        setTimeout(() => {
            $('.intro').addClass('pulsingHeart');
        }, 3000);
    }

});

if(portfolioPage.length > 0){
    let grid = document.querySelector('.mas-grid');

    var msnry = new Masonry( grid, {
        gutter: 10,
        itemSelector: '.pm__item',
        columnWidth: '.mas-grid-sizer',
        percentPosition: true
    });
    imagesLoaded( grid ).on( 'progress', function() {
        msnry.layout();
    });
}