import $ from "jquery";
import {
    TimelineMax,
    Power3,
    TweenMax
} from 'gsap';
import headerAnimation from './headerAnim.js';
import slick from 'slick-carousel';
const expertPage = $('.expertise-page');


// class expertiseSlide{
//     constructor(){

//     }

//     enter(){
//         let tlIntro = new TimelineMax();
//         tlIntro.fromTo(firstSection.elem.title, 2,
//             {y: '100%',opacity: 0},
//             {y: 0, opacity: 1}
//         );
//     }
//     leave(){
//         let tlIntro = new TimelineMax();
//         tlIntro.fromTo(firstSection.elem.title, 2,
//             {y: 0, opacity: 1},
//             {y: '100%',opacity: 0}
//         );
//     }

// }

if (expertPage.length > 0) {


    let activeSection;
    let activeSlide;
    let expertiseSlide1 = {
        elem: {
            parent: $('.expertise-slider'),
            num: $('.expertise-slide1 .slide__num'),
            title: $('.expertise-slide1 .slide__title'),
            text: $('.expertise-slide1 .slide__subtitle, .expertise-slide1 p'),
            top: $('.expertise-slide1 .slide__top'),
            bottom: $('.expertise-slide1 .slide__bottom'),
            right: $('.expertise-slide1 .slide__right'),
            img: $('.expertise-slide1 .slide__img'),
            decorCircle: $('.expertise-slide1 .circle'),
            decorLine: $('.expertise-slide1 .line')
        },
        enter: function () {
            let expertiseSlide1Enter = new TimelineMax();
            expertiseSlide1Enter
                .to(expertiseSlide1.elem.top, 1, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide1.elem.bottom, 1, {
                    transformOrigin: "100% 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide1.elem.img, 1, {
                    width: "100%",
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide1.elem.title, 0.7, {
                    y: '0',
                    opacity: 1,
                }, 0.4)
                .to(expertiseSlide1.elem.decorCircle, 0.5, {
                    x: '0',
                    opacity: 1,
                }, 1)
                .to(expertiseSlide1.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                }, 1)
                .to(expertiseSlide1.elem.num, 0.7, {
                    x: '0',
                    opacity: 1,
                }, 0.5)
                .staggerFromTo(expertiseSlide1.elem.text, 0.7, {
                    y: '-20px',
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1
                }, 0.1, 0.5)
                .to(expertiseSlide1.elem.parent, 0.7, {
                    css: {
                        'background-color': '#CCBFB7'
                    }
                }, 0.5)

        },
        leave: function () {
            let expertiseSlide1Leave = new TimelineMax();
            expertiseSlide1Leave
                .to(expertiseSlide1.elem.title, 0.7, {
                    y: '-20px',
                    opacity: 0,
                })
                .to(expertiseSlide1.elem.num, 0.7, {
                    x: '-20px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide1.elem.decorCircle, 0.5, {
                    x: '-50px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide1.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: '0.2',
                    opacity: 0,
                }, 0)
                .staggerFromTo(expertiseSlide1.elem.text, 0.7, {
                    y: 0,
                    opacity: 1
                }, {
                    y: '-20px',
                    opacity: 0
                }, 0.1, 0.2)
                .to(expertiseSlide1.elem.top, 1, {
                    transformOrigin: "0 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)
                .to(expertiseSlide1.elem.bottom, 1, {
                    transformOrigin: "100% 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)
                .to(expertiseSlide1.elem.img, 1, {
                    width: "0",
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)

        }
    }
    let expertiseSlide2 = {
        elem: {
            parent: $('.expertise-slider'),
            num: $('.expertise-slide2 .slide__num'),
            title: $('.expertise-slide2 .slide__title'),
            text: $('.expertise-slide2 .slide__subtitle, .expertise-slide2 p'),
            top: $('.expertise-slide2 .slide__top'),
            bottom: $('.expertise-slide2 .slide__bottom'),
            right: $('.expertise-slide2 .slide__right'),
            img: $('.expertise-slide2 .slide__img'),
            decorCircle: $('.expertise-slide2 .circle'),
            decorLine: $('.expertise-slide2 .line')
        },
        enter: function () {
            let expertiseSlide2Enter = new TimelineMax();
            expertiseSlide2Enter

                .to(expertiseSlide2.elem.top, 1, {
                    transformOrigin: "100% 0",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide2.elem.bottom, 1, {
                    transformOrigin: "0% 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide2.elem.img, 1, {
                    width: '100%',
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0.5)
                .to(expertiseSlide2.elem.title, 0.7, {
                    y: '0',
                    opacity: 1,
                }, 0.4)
                .to(expertiseSlide2.elem.decorCircle, 0.5, {
                    x: '0',
                    opacity: 1,
                }, 1)
                .to(expertiseSlide2.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                }, 1)
                .to(expertiseSlide2.elem.num, 0.7, {
                    x: '0',
                    opacity: 1,
                }, 0.5)
                .staggerFromTo(expertiseSlide2.elem.text, 0.7, {
                    y: '-20px',
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1
                }, 0.1, 0.5)
                .to(expertiseSlide2.elem.parent, 0.7, {
                    css: {
                        'background-color': '#ffffff'
                    }
                }, 0.5)
        },
        leave: function () {
            let expertiseSlide2Leave = new TimelineMax();
            expertiseSlide2Leave
                .to(expertiseSlide2.elem.title, 0.7, {
                    y: '-20px',
                    opacity: 0,
                })
                .to(expertiseSlide2.elem.num, 0.7, {
                    x: '-20px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide2.elem.decorCircle, 0.5, {
                    x: '-50px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide2.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: '0.2',
                    opacity: 0,
                }, 0)
                .staggerFromTo(expertiseSlide2.elem.text, 0.7, {
                    y: 0,
                    opacity: 1
                }, {
                    y: '-20px',
                    opacity: 0
                }, 0.1, 0)

                .to(expertiseSlide2.elem.top, 1, {
                    transformOrigin: "100% 0",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.2)
                .to(expertiseSlide2.elem.bottom, 1, {
                    transformOrigin: "0% 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.3)
                .to(expertiseSlide2.elem.img, 1, {
                    width: "0",
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)

        }
    }
    let expertiseSlide3 = {
        elem: {
            parent: $('.expertise-slider'),
            num: $('.expertise-slide3 .slide__num'),
            title: $('.expertise-slide3 .slide__title'),
            text: $('.expertise-slide3 .slide__subtitle, .expertise-slide2 p'),
            top: $('.expertise-slide3 .slide__top'),
            right: $('.expertise-slide3 .slide__right'),
            img: $('.expertise-slide3 .slide__img')

        },
        enter: function () {
            let expertiseSlide3Enter = new TimelineMax();
            expertiseSlide3Enter
                .to(expertiseSlide3.elem.top, 1, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)

                .to(expertiseSlide3.elem.img, 1, {
                    width: "100%",
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide3.elem.title, 0.7, {
                    y: '0',
                    opacity: 1,
                }, 0.4)

                .to(expertiseSlide3.elem.num, 0.7, {
                    x: '0',
                    opacity: 1,
                }, 0.5)
                .staggerFromTo(expertiseSlide3.elem.text, 0.7, {
                    y: '-20px',
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1
                }, 0.1, 0.5)
                .to(expertiseSlide3.elem.parent, 0.7, {
                    css: {
                        'background-color': '#bce1e2'
                    }
                }, 0.5)

        },
        leave: function () {
            let expertiseSlide3Leave = new TimelineMax();
            expertiseSlide3Leave
                .to(expertiseSlide3.elem.title, 0.7, {
                    y: '-20px',
                    opacity: 0,
                })
                .to(expertiseSlide3.elem.num, 0.7, {
                    x: '-20px',
                    opacity: 0,
                }, 0)
                .staggerFromTo(expertiseSlide3.elem.text, 0.7, {
                    y: 0,
                    opacity: 1
                }, {
                    y: '-20px',
                    opacity: 0
                }, 0.1, 0.2)
                .to(expertiseSlide3.elem.top, 1, {
                    transformOrigin: "0 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)

                .to(expertiseSlide3.elem.img, 1, {
                    width: "0",
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)

        }
    }
    let expertiseSlide4 = {
        elem: {
            parent: $('.expertise-slider'),
            num: $('.expertise-slide4 .slide__num'),
            title: $('.expertise-slide4 .slide__title'),
            text: $('.expertise-slide4 .slide__subtitle, .expertise-slide4 p'),
            top: $('.expertise-slide4 .slide__top'),
            bottom: $('.expertise-slide4 .slide__bottom'),
            right: $('.expertise-slide4 .slide__right'),
            img: $('.expertise-slide4 .slide__img'),
            decorCircle: $('.expertise-slide4 .circle'),
            decorLine: $('.expertise-slide4 .line')
        },
        enter: function () {
            let expertiseSlide4Enter = new TimelineMax();
            expertiseSlide4Enter

                .to(expertiseSlide4.elem.top, 1, {
                    transformOrigin: "100% 0",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide4.elem.bottom, 1, {
                    transformOrigin: "0% 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide4.elem.img, 1, {
                    width: '100%',
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0.5)
                .to(expertiseSlide4.elem.title, 0.7, {
                    y: '0',
                    opacity: 1,
                }, 0.4)
                .to(expertiseSlide4.elem.decorCircle, 0.5, {
                    x: '0',
                    opacity: 1,
                }, 1)
                .to(expertiseSlide4.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                }, 1)
                .to(expertiseSlide4.elem.num, 0.7, {
                    x: '0',
                    opacity: 1,
                }, 0.5)
                .staggerFromTo(expertiseSlide4.elem.text, 0.7, {
                    y: '-20px',
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1
                }, 0.1, 0.5)
                .to(expertiseSlide4.elem.parent, 0.7, {
                    css: {
                        'background-color': '#ffffff'
                    }
                }, 0.5)
        },
        leave: function () {
            let expertiseSlide4Leave = new TimelineMax();
            expertiseSlide4Leave
                .to(expertiseSlide4.elem.title, 0.7, {
                    y: '-20px',
                    opacity: 0,
                })
                .to(expertiseSlide4.elem.num, 0.7, {
                    x: '-20px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide4.elem.decorCircle, 0.5, {
                    x: '-50px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide4.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: '0.2',
                    opacity: 0,
                }, 0)
                .staggerFromTo(expertiseSlide4.elem.text, 0.7, {
                    y: 0,
                    opacity: 1
                }, {
                    y: '-20px',
                    opacity: 0
                }, 0.1, 0)

                .to(expertiseSlide4.elem.top, 1, {
                    transformOrigin: "100% 0",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.2)
                .to(expertiseSlide4.elem.bottom, 1, {
                    transformOrigin: "0% 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.3)
                .to(expertiseSlide4.elem.img, 1, {
                    width: "0",
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0)

        }
    }
    let expertiseSlide5 = {
        elem: {
            parent: $('.expertise-slider'),
            num: $('.expertise-slide5 .slide__num'),
            title: $('.expertise-slide5 .slide__title'),
            text: $('.expertise-slide5 .slide__subtitle, .expertise-slide5 p'),
            top: $('.expertise-slide5 .slide__top'),
            bottom: $('.expertise-slide5 .slide__bottom'),
            right: $('.expertise-slide5 .slide__right'),
            img: $('.expertise-slide5 .slide__img'),
            decorCircle: $('.expertise-slide5 .circle'),
            decorLine: $('.expertise-slide5 .line')
        },
        enter: function () {
            let expertiseSlide5Enter = new TimelineMax();
            expertiseSlide5Enter
                .to(expertiseSlide5.elem.top, 1, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide5.elem.bottom, 1, {
                    transformOrigin: "100% 50%",
                    scaleX: 1,
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide5.elem.img, 1, {
                    width: "100%",
                    opacity: 1,
                    ease: Power3.easeOut
                }, 0)
                .to(expertiseSlide5.elem.title, 0.7, {
                    y: '0',
                    opacity: 1,
                }, 0.4)
                .to(expertiseSlide5.elem.decorCircle, 0.5, {
                    x: '0',
                    opacity: 1,
                }, 1)
                .to(expertiseSlide5.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: 1,
                    opacity: 1,
                }, 1)
                .to(expertiseSlide5.elem.num, 0.7, {
                    x: '0',
                    opacity: 1,
                }, 0.5)
                .staggerFromTo(expertiseSlide5.elem.text, 0.7, {
                    y: '-20px',
                    opacity: 0
                }, {
                    y: 0,
                    opacity: 1
                }, 0.1, 0.5)
                .to(expertiseSlide5.elem.parent, 0.7, {
                    css: {
                        'background-color': '#bce1e2'
                    }
                }, 0.5)

        },
        leave: function () {
            let expertiseSlide5Leave = new TimelineMax();
            expertiseSlide5Leave
                .to(expertiseSlide5.elem.title, 0.7, {
                    y: '-20px',
                    opacity: 0,
                })
                .to(expertiseSlide5.elem.num, 0.7, {
                    x: '-20px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide5.elem.decorCircle, 0.5, {
                    x: '-50px',
                    opacity: 0,
                }, 0)
                .to(expertiseSlide5.elem.decorLine, 0.5, {
                    transformOrigin: "0 50%",
                    scaleX: '0.2',
                    opacity: 0,
                }, 0)
                .staggerFromTo(expertiseSlide5.elem.text, 0.7, {
                    y: 0,
                    opacity: 1
                }, {
                    y: '-20px',
                    opacity: 0
                }, 0.1, 0.2)
                .to(expertiseSlide5.elem.top, 1, {
                    transformOrigin: "0 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)
                .to(expertiseSlide5.elem.bottom, 1, {
                    transformOrigin: "100% 50%",
                    scaleX: 0,
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)
                .to(expertiseSlide5.elem.img, 1, {
                    width: "0",
                    opacity: 0,
                    ease: Power3.easeOut
                }, 0.5)

        }
    }
    if ($(window).width() > 1023) {
        expertiseSlide2.leave();
        expertiseSlide3.leave();
        expertiseSlide4.leave();
        expertiseSlide5.leave();
    }



    function animationChangeSlide(slideLeave, slideEnter) {
        // console.log(slideLeave);
        // console.log(slideEnter);

        $(`.expertise-slide${slideLeave}, .expertise-slide${slideEnter}`).css('visibility', 'visible');
        $(`.slide:not(.expertise-slide${slideLeave}):not(.expertise-slide${slideEnter})`).css('visibility', 'hidden');

        if (slideLeave === 1) {
            expertiseSlide1.leave();
        }
        if (slideEnter === 1) {
            expertiseSlide1.enter();
        }

        if (slideLeave === 2) {
            expertiseSlide2.leave();
        }
        if (slideEnter === 2) {
            expertiseSlide2.enter();
        }

        if (slideLeave === 3) {
            expertiseSlide3.leave();
        }
        if (slideEnter === 3) {
            expertiseSlide3.enter();
        }

        if (slideLeave === 4) {
            expertiseSlide4.leave();
        }
        if (slideEnter === 4) {
            expertiseSlide4.enter();
        }

        if (slideLeave === 5) {
            expertiseSlide5.leave();
        }
        if (slideEnter === 5) {
            expertiseSlide5.enter();
        }


        // expertiseSlide------.enter()

        // let slideFunctLive = 'expertiseSlide'+slideLeave;

        // slideFunctLive.leave();
    }

    var aForResizeExpertise;
    $(window).resize(function () {
        clearTimeout(aForResizeExpertise);
        aForResizeExpertise = setTimeout(function () {
            if ($(window).width() > 1023) {
                expertiseSlide2.leave();
                expertiseSlide3.leave();
                expertiseSlide4.leave();
                expertiseSlide5.leave();
            } else {
                expertiseSlide1.enter();
                expertiseSlide2.enter();
                expertiseSlide3.enter();
                expertiseSlide4.enter();
                expertiseSlide5.enter();
            }
        }, 200);
    });


    var slideIndex = 1,
        sliding = false;

    function expertiseFullpage() {
        if ($(window).width() <= 1023 && $('.layout__main').hasClass('fullpage-wrapper') && $('body').hasClass('expertise-page')) {
            // console.log("destroy fullpage");
            $.fn.fullpage.destroy('all');
        } else if ($(window).width() > 1023 && !$('.layout__main').hasClass('fullpage-wrapper')) {
            // console.log('sdf  fsdfsfdfsdfs');
            $('.expertise-page .layout__main').fullpage({
                sectionSelector: '.section',
                scrollingSpeed: 1000,
                responsiveWidth: 1024,
                css3: false,
                slidesNavigation: true,
                slidesNavPosition: 'bottom',
                loopHorizontal: false,
                dragAndMove: false,
                fadingEffect: 'slides',
                controlArrows: false,
                // easing: Power3.easeOut,
                normalScrollElements: '.latest-activities__inner',
                afterLoad: function (origin, destination, direction) {
                    activeSection = destination;
                },

                afterSlideLoad: function (section, origin, destination, direction) {
                    var loadedSlide = this;

                    let color = $('.slide.active').attr('data-slide_nav');
                    // setExpertiseSliderNavColor(color)

                    sliding = false;

                    slideIndex = destination + 1;

                    //submenu
                    // let $nav = $('#expertiseMenu');
                    // if(!$nav) return true;
                    // $('a', $nav).removeClass('active');
                    // $('li', $nav).eq(slideIndex - 1).find('a').addClass('active');


                },
                onLeave: function (origin, destination, direction) {
                    let countExpertiseSlides = $('.expertise-slider .slide').length;
                    // console.log(countExpertiseSlides);
                    //slider
                    if ($(window).width() > 1023) {
                        if (origin == 2 && !sliding) {
                            if (direction == 'down' && slideIndex < countExpertiseSlides) {
                                sliding = true;
                                $.fn.fullpage.moveSlideRight();
                                slideIndex++;
                                animationChangeSlide(slideIndex - 1, slideIndex);
                                return false;

                            } else if (direction == 'up' && slideIndex > 1) {

                                sliding = true;
                                $.fn.fullpage.moveSlideLeft();
                                slideIndex--;
                                animationChangeSlide(slideIndex + 1, slideIndex);
                                return false;

                            }

                        } else if (sliding) {

                            return false;

                        }
                    }

                    headerAnimation(origin, destination, direction);


                }
            });
        }
    }
    expertiseFullpage();

    $('.expertise-page .fp-slidesNav li').on('click', function(){
        let current = $('.expertise-page .fp-slidesNav a.active').closest('li').index();
        let thatIndex = $(this).index();
        if(current === thatIndex){
            return;
        } else {
            animationChangeSlide(current + 1, thatIndex + 1);
        }


    });



        let $installSlider = $('#installSlider');
        // console.log($installSlider);
        $installSlider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 2000,
        });


    var aForResize;
    $(window).resize(function() {
        clearTimeout(aForResize);
        aForResize = setTimeout(function() {
            expertiseFullpage();
        },200);
    });

    //submenu
    // function createNav(){
    //     let $nav = $('#expertiseMenu');
    //     if(!$nav) return true;

    //     $nav.append('<ul></ul>');

    //     let $parent = $('.expertise-slider');
    //     let titles = $('.slide__title', $parent);
    //     let titlesText = [];
    //     $.each(titles, function(i,el){
    //         titlesText.push($(el).text());
    //     });

    //     $.each(titlesText, function(i,el){
    //         $('ul', $nav).append('<li><a href="#">'+el+'</a></li>');
    //     });

    //     $('li:first-child a', $nav).addClass('active');

    //     let navEl = $('a', $nav);

    //     $.each(navEl, function(i,el){
    //         $(el).on('click',function(e){
    //             e.stopPropagation();
    //             e.preventDefault();
    //             if($(this).hasClass('active')) return;
    //             let indx = $(this).closest('li').index() + 1;
    //             $.fn.fullpage.moveTo(activeSection,indx - 1);
    //             animationChangeSlide(slideIndex,indx);
    //             slideIndex = indx;
    //             $('a', $nav).removeClass('active');
    //             $('li', $nav).eq(slideIndex - 1).find('a').addClass('active');

    //         });
    //     });

    // }

    $(function() {

        // createNav();
        setTimeout(() => {
            $('.intro').addClass('pulsingHeart');
        }, 3000);
    });
}









// export default animationChangeSlide;