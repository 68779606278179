window.$ = window.jQuery = require('jquery');
// import {TimelineMax, Power3} from 'gsap';


const contactPage = $('.contact-page, .contact-page-success');
const staticPage = $('.static-page');


$(function() {
    if(contactPage.length > 0 || staticPage.length > 0){
        $('.intro').addClass('active');
        setTimeout(() => {
            $('.intro').addClass('pulsingHeart');
        }, 3000);
    }
});
