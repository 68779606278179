import $ from "jquery";
import ScrollMagic from 'scrollmagic';
import {TimelineMax, Power3, TweenMax, gsap} from 'gsap';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
let controller = new ScrollMagic.Controller();

// export default controller;

window.controller = controller;