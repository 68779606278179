import $ from "jquery";
import {TimelineMax, Power3, TweenMax} from 'gsap';
// header

const $headerWrapper = $('.layout__header');
const $portfolioInner = $('.portfolio-inner-page');
let $header = $('header.header'),
    $logoWrapper = $('header.header .logo'),
    $logoWhite = $('.logo-white'),
    $logoBrown = $('.logo-brown'),
    $navs = $('.header__nav .nav__item'),
    $portfolioClose = $('.header .header__portfolio-close'),
    headerMorphing = false;


$(document).ready(function(){
    setTimeout(() => {
        if($('.layout__main').hasClass('fullpage-wrapper')){
            return;
        } else {
            $('.layout__header > .header').addClass('whithoutFullpage');
        }
    }, 10);

})


// let lastScrollTop = 0;
$(window).scroll(function () {
   const st = $(this).scrollTop();

   if($(window).width() < 1024 || $header.hasClass('whithoutFullpage')){
    //    console.log(st);
       if($headerWrapper){
            if($(window).scrollTop() >= 80){
                $header.addClass('is-fixed');
                fromOpacityVisibleToWhiteVisible();
            } else {
                // console.log('sdfsdf');
                $header.removeClass('is-fixed');
                fromWhiteVisibleToOpacityVisible();
            }
            // if(!$(window).width() > 1024){
            //     if (st > lastScrollTop){
            //         $('.layout__header').removeClass('is-fixed');
            //     } else if(st <= lastScrollTop) {
            //         if($(window).scrollTop() > 100){
            //             $('.layout__header').addClass('is-fixed');
            //         } else {
            //             $('.layout__header').removeClass('is-fixed');
            //         }
            //     }
            //     lastScrollTop = st;
            // }
       }
   }

});

let headerLogoStartSize = $('.header__logo .logo').width();
let headerLogoSize = $(window).width() > 1023 ? '74px' : '112px';


let fromOpacityVisibleToWhiteVisible = function(){

        if(headerMorphing === false){


            let tlHeader1 = new TimelineMax({paused:true});

            if($(window).width() > 1023){
                if($portfolioInner.length > 0){

                    tlHeader1
                        .to($logoWrapper, 0.2,
                            { width: headerLogoSize, height: headerLogoSize, backgroundColor: '#ffffff', borderColor: '#ffffff', y: 9},
                        0)
                        .fromTo($logoWhite, .3,
                            { opacity: 1 },
                            { opacity: 0 },
                        0)
                        .fromTo($logoBrown, .3,
                            { opacity: 0 },
                            { opacity: 1},
                        0)

                        .fromTo($header, 0.5,
                            { backgroundColor: 'rgba(255,255,255,.0)', top: '48px', y: -100},
                            { backgroundColor: 'rgba(255,255,255,1)', top: 0, y: 0},
                        0)
                        .fromTo($portfolioClose, 0.3,
                            { color: '#ffffff', autoAlpha: 0, y: -25},
                            { color: '#474341', autoAlpha: 1, y: 0},
                        0)
                } else {
                    tlHeader1
                        .to($logoWrapper, 0.2,
                            { width: headerLogoSize, height: headerLogoSize, backgroundColor: '#ffffff', borderColor: '#ffffff', y: 9},
                        0)
                        .fromTo($logoWhite, .3,
                            { opacity: 1 },
                            { opacity: 0 },
                        0)
                        .fromTo($logoBrown, .3,
                            { opacity: 1 },
                            { opacity: 1},
                        "-=0.2")

                        .fromTo($header, 0.5,
                            { backgroundColor: 'rgba(255,255,255,.0)', top: '48px', y: -100},
                            { backgroundColor: 'rgba(255,255,255,1)', top: 0, y: 0},
                        0)
                        .staggerFromTo($navs, 0.75,
                            { color: '#ffffff', autoAlpha: 0, y: -25},
                            { color: '#474341', autoAlpha: 1, y: 0},
                        0.2, "-=0.2")
                }


            } else {
                if($portfolioInner.length > 0){
                    tlHeader1
                        .to($logoWrapper, 0.2,
                            {backgroundColor: '#ffffff', borderColor: '#ffffff', y: 9},
                        0)
                        .fromTo($logoWhite, 0.3,
                            { opacity: 1 },
                            { opacity: 0 },
                        0)
                        .fromTo($logoBrown, 0.3,
                            { opacity: 0 },
                            { opacity: 1},
                        0)
                        .fromTo($header, 0.3,
                            { backgroundColor: 'rgba(255,255,255,.0)', top: $(window).width() < 768 ? '' : '24px', y: -100},
                            { backgroundColor: 'rgba(255,255,255,1)', top: 0, y: 0},
                        0)
                        .fromTo($portfolioClose, 0.3,
                            { color: '#ffffff', autoAlpha: 0, y: -25},
                            { color: '#474341', autoAlpha: 1, y: 0},
                        0)
                } else {
                    tlHeader1
                        .to($logoWrapper, 0.2,
                            {backgroundColor: '#ffffff', borderColor: '#ffffff', y: 9},
                        0)
                        .fromTo($logoWhite, 0.3,
                            { opacity: 1 },
                            { opacity: 0 },
                        0)
                        .fromTo($logoBrown, 0.3,
                            { opacity: 0 },
                            { opacity: 1},
                        0)
                        .fromTo($header, 0.3,
                            { backgroundColor: 'rgba(255,255,255,.0)', top: $(window).width() < 768 ? '' : '24px', y: -100},
                            { backgroundColor: 'rgba(255,255,255,1)', top: 0, y: 0},
                        0)
                        .fromTo($navs, 0.3,
                            { color: '#ffffff', autoAlpha: 0},
                            { color: '#474341', autoAlpha: 1},
                        0)
                }
            }
            headerMorphing = true;
            tlHeader1.restart().timeScale(1);
        }

}


let fromWhiteVisibleToOpacityVisible = function(){

    if(headerMorphing === true){


        let tlHeader3 = new TimelineMax({paused:true});

        if($(window).width() > 1023){
            if($portfolioInner.length > 0){
                tlHeader3
                    .fromTo($header, 0.2,
                        { backgroundColor: 'rgba(255,255,255,1)', top: 0,},
                        { backgroundColor: 'rgba(255,255,255,.0)', top: '48px'},
                    0)
                    .to($logoWrapper, 0.2,
                        { width: headerLogoStartSize, height: headerLogoStartSize, backgroundColor: 'rgba(255,255,255,.0)', borderColor: 'rgba(255,255,255,.0)', y: 10},
                    0)
                    .fromTo($portfolioClose, 0.5,
                        { color: '#474341'},
                        { color: '#ffffff'},
                        0, )


                    .fromTo($logoWhite, .2,
                        { opacity: 0 },
                        { opacity: 1 },
                        0)

                    .fromTo($logoBrown, .2,
                        { opacity: 1},
                        { opacity: 0},
                        0)
                    ;
            } else {
                tlHeader3
                    .fromTo($header, 0.2,
                        { backgroundColor: 'rgba(255,255,255,1)', top: 0,},
                        { backgroundColor: 'rgba(255,255,255,.0)', top: '48px'},
                    0)
                    .to($logoWrapper, 0.2,
                        { width: headerLogoStartSize, height: headerLogoStartSize, backgroundColor: 'rgba(255,255,255,.0)', borderColor: 'rgba(255,255,255,.0)', y: 10},
                    0)
                    .staggerFromTo($navs, 0.5,
                        { color: '#474341'},
                        { color: '#ffffff'},
                        0.12, "+=0.0001")


                    .fromTo($logoWhite, .2,
                        { opacity: 0 },
                        { opacity: 1 },
                        0)

                    .fromTo($logoBrown, .5,
                        { opacity: 1},
                        { opacity: 0},
                        0)
                    ;
            }

        } else {
            if($portfolioInner.length > 0){
                tlHeader3
                    .to($logoWrapper, 0.2,
                        { backgroundColor: 'rgba(255,255,255,.0)', borderColor: 'rgba(255,255,255,.0)', y: 10},
                    0)
                    .fromTo($logoWhite, 0.3,
                        { opacity: 0 },
                        { opacity: 1 },
                    0)
                    .fromTo($logoBrown, 0.3,
                        { opacity: 1},
                        { opacity: 0 },
                    0)
                    .fromTo($header, 0.3,
                        { backgroundColor: 'rgba(255,255,255,1)', top: 0},
                        { backgroundColor: 'rgba(255,255,255,.0)', top: $(window).width() < 768 ? '' : '24px'},
                    0)
                    .fromTo(portfolioClose, 0.3,
                        { color: '#474341'},
                        { color: $(window).width() < 768 ? '' : '#ffffff'},
                    0)
            } else{
                tlHeader3
                    .to($logoWrapper, 0.2,
                        { backgroundColor: 'rgba(255,255,255,.0)', borderColor: 'rgba(255,255,255,.0)', y: 10},
                    0)
                    .fromTo($logoWhite, 0.3,
                        { opacity: 0 },
                        { opacity: 1 },
                    0)
                    .fromTo($logoBrown, 0.3,
                        { opacity: 1},
                        { opacity: 0},
                    0)
                    .fromTo($header, 0.3,
                        { backgroundColor: 'rgba(255,255,255,1)', top: 0},
                        { backgroundColor: 'rgba(255,255,255,.0)', top: $(window).width() < 768 ? '' : '24px'},
                    0)

                    .fromTo($navs, 0.3,
                        { color: '#474341'},
                        { color: $(window).width() < 768 ? '' : '#ffffff'},
                    0)
            }
        }

        headerMorphing = false;
        tlHeader3.restart().timeScale(1);

    }
}


function headerAnimation(origin,destination,direction){
    if(origin === 1 && destination === 2){
        fromOpacityVisibleToWhiteVisible();

    }
    if(origin === 2 && destination === 1){
        fromWhiteVisibleToOpacityVisible();

    }

}


// function headerLogoResize(){
//     let tlHeaderResize = new TimelineMax({paused:true});
//     tlHeaderResize.to($logoWrapper, 0.2,
//         { width: headerLogoSize, height: headerLogoSize},
//     0)
// }





var rForResize;
$(window).resize(function() {
    clearTimeout(rForResize);
    rForResize = setTimeout(function() {
        // headerLogoResize();
        // console.log('sdfsdf');
    },200);
});



export default headerAnimation;
//header end