window.$ = window.jQuery = require('jquery');
import {TweenMax} from 'gsap';


let pathPrefix = '';
function imgPath(path) {return pathPrefix + 'assets/img/'+ path}
const aboutPage = $('.about-page');
let aboutSlider = $('#craftsmenList');

function mobileOnlySlider() {
    aboutSlider.slick({
        autoplay: false,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true,
        dots: false,
    });
}
if(window.innerWidth < 768) {
    mobileOnlySlider();
}

$(window).resize(function(e){
    if(window.innerWidth < 768) {
        if(!aboutSlider.hasClass('slick-initialized')){
            mobileOnlySlider();
        }

    }else{
        if(aboutSlider.hasClass('slick-initialized')){
            aboutSlider.slick('unslick');
        }
    }
});

function peopleOnHover(){
    // let peoples = $('.craftsmen-list__inner img');
    let peoples = document.querySelectorAll('.craftsmen-list__inner');


    Array.prototype.forEach.call(peoples, function (people) {
        // console.log(job.textContent + ' is a great job!');
        let $parent = people;
        let img = people.dataset.src;
        let img2 = people.dataset.srcHover;

        let newImg = document.createElement('img');
        $parent.appendChild(newImg);
        newImg.src = img2;
        newImg.classList.add('imgOnHover');
        // let animOnHover = new hoverEffect({
        //     parent: $parent,
        //     intensity: 0.2,
        //     image1: img,
        //     image2: img2,
        //     displacementImage: '/assets/img/about/test/diss.png',
        //     imagesRatio: 100 / 100
        // })
    });

}



$(function() {
    if(aboutPage.length > 0){
        $('.intro').addClass('active');
        setTimeout(() => {
            $('.intro').addClass('pulsingHeart');
        }, 3000);

        setTimeout(() => {
            peopleOnHover();
        }, 200);


    }
})