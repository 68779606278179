import $ from "jquery";
import './components/jquery.fullpage.extensions.min';
import slick from 'slick-carousel';
import './components/pixi.js';
import {
    TimelineMax,
    Power3,
    TweenMax
} from 'gsap';
import './components/sliderDisp.js';


import headerAnimation from './components/headerAnim.js';
import './components/expertise.js';

import mainpageFullpage from './components/main-page.js';
import './components/portfolio.js';
import './components/contact.js';
import './components/about.js';

// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';


let pathPrefix = '';

function imgPath(path) {
    return pathPrefix + 'assets/img/' + path
}



const expertPage = $('.expertise-page');
const portfolioPage = $('.portfolio-page');



function smoothScroll() {

    let links = $('a[href*="#"]:not([href="#"])');

    function scrollTo(id) {
        let el = $(id);

        if (!el.length) {
            return;
        }

        $('html, body').animate({
            scrollTop: el.offset().top - 100
        }, 500);
    }

    links.on('click', function (e) {
        e.preventDefault();
        scrollTo($(this).attr('href'))
    });

}


var videoBg = $(".intro__bgVideo"),
    videoFrame = $('.video__wrapper iframe');
if (videoFrame[0]) {
    var videoVimeoContentWindow = videoFrame[0].contentWindow,
        videoVimeoTargetOriginUrl = videoFrame.attr('src').split('?')[0],
        videoYoutubeTargetOriginUrl = videoFrame.attr('src');

    if (videoYoutubeTargetOriginUrl.indexOf('youtube') > -1) {
        var newSource = videoYoutubeTargetOriginUrl.replace('&autoplay=1', '&autoplay=0');
        videoFrame.attr('src', newSource);
    }

    $('#js-bgVideo-trigger').on('click', function () {
        if (videoVimeoTargetOriginUrl.indexOf('vimeo') > -1) {
            videoVimeoContentWindow.postMessage({
                'method': 'play'
            }, videoVimeoTargetOriginUrl);
        } else if (videoYoutubeTargetOriginUrl.indexOf('youtube') > -1) {
            videoFrame.attr('src', videoYoutubeTargetOriginUrl.replace('&autoplay=0', '&autoplay=1'));
        }
        videoBg.addClass("show");
    });

    $('#js-bgVideo-close').on('click', function () {
        if (videoVimeoTargetOriginUrl.indexOf('vimeo') > -1) {
            videoVimeoContentWindow.postMessage({
                'method': 'pause'
            }, videoVimeoTargetOriginUrl);
            var legVimeo = videoFrame.attr('src');
            videoFrame
                .attr('src', legVimeo);
        } else if (videoYoutubeTargetOriginUrl.indexOf('youtube') > -1) {
            videoFrame.attr('src', videoYoutubeTargetOriginUrl.replace('&autoplay=1', '&autoplay=0'));
        }
        videoBg.removeClass("show");
    });

}


$(document).on('click', '.scroll-btn', function (e) {
    e.preventDefault();
    if ($('html').hasClass('fp-enabled')) {
        $.fn.fullpage.moveSectionDown();
    } else {
        let newTarget = $(this).closest('.section').next('.section');
        $('html, body').animate({
            scrollTop: newTarget.offset().top
        }, 500);
    }
});

// menu
$(document).on('click', '.mobile-trigger-nav', function () {
    $('body').addClass('menu-open');
});
$(document).on('click', '#close-menu', function () {
    $('body').removeClass('menu-open');
});


$('.latest-activities__trigger,.latest-activities__close,.js-open-activities').on('click', function(){
    let $target = $('.latest-activities');

    if($target){
        if($target.hasClass('is-open')){
            $target.removeClass('is-open');
            $('body').removeClass('la-is-open');
            $('.latest-activities__trigger__popover').addClass('visible');
            localStorage.setItem('hideLa', true);
        } else {
            $target.addClass('is-open');
            $('body').addClass('la-is-open');
            $('.latest-activities__trigger__popover').removeClass('visible');
            localStorage.removeItem('hideLa');
        }
    }
})


// document.ready
$(function () {

    // intro video
    // videoBg();
    smoothScroll();


    if(localStorage.getItem("hideLa") != null){
        $('body').removeClass('la-is-open');
        $('.latest-activities').removeClass('is-open');
    } else {
        $('body').addClass('la-is-open');
        $('.latest-activities').addClass('is-open');
    }


    //video on inner page NOT INTRO
    let video = $(".aspect-youtube"),
        videoFrame = $(".aspect-youtube .overlay");
    $(document).on("click", ".btn-video__icon:not(#js-bgVideo-trigger)", function (e) {
            console.log('sazsd');

            video.addClass("show"),
                videoFrame[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*")
        }),
        $(document).on("click", "#js-video-close", function (e) {
            videoFrame[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', "*"),
                video.removeClass("show")
        })


    if(window.matchMedia("(max-width: 767px)").matches){
        let activities = $('#latestActivities');
        if(activities.length < 1) return;
        activities.removeClass('is-open');
        $('body').removeClass('la-is-open');

    }
    setTimeout(() => {
        $('body').addClass('readyToOpenPopups');
    }, 100);

})